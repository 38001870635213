import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './createServer.module.css';

import { getTracks } from './TrackScreenApi';
import { getCarPacks } from './getCarPacks';

import axios from 'axios';
import {
  accmPrefix,
  API_URL,
  AC_MANAGER,
  AC_IP,
  API_KEY,
  acServerApiHeader
} from '../../config';
import { Clipboard } from '../../extras';
import { acLauncher } from '../../acLauncher';

function CreateServer(props) {
  const navigate = useNavigate();
  const [link, setLink] = useState(null);
  const [isLaunchDisabled, setIsLaunchDisabled] = useState(true);
  const [isServerRequested, setIsServerRequested] = useState(false);
  const [port, setPort] = useState(0);
  const [language, setLanguage] = useState('en');
  const [translation, setTranslation] = useState({
    "launchServerButton": {
      "en": "Launch",
      "pl": "Uruchom"
    }
  });
  const [trackSelector, setTrackSelector] = useState('Select track');
  const [carpackSelector, setCarpackSelector] = useState('Select car pack');
  const [isOpenTrackSelector, setIsOpenTrackSelector] = useState(false);
  const [isOpenCarpackSelector, setIsOpenCarpackSelector] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [track, setTrack] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(0);
  const [serverTrackConfig, setServerTrackConfig] = useState(0);
  const [isServerPublic, setIsServerPublic] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  document.title = "G8rage - Create Server";

  const toggleServerVisibility = () => {
    setIsServerPublic(isServerPublic ? false : true);
  }

  async function getRoles() {
    try {
      const data = {
        token: props.cookie.token
      };

      const response = await axios.post(`${API_URL}user/roles/`, JSON.stringify(data));

      if (response.status === 200) {
        setIsUserAdmin(response.data.isAdmin);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const selector = (type, state) => {
    if (type == 'track') {
      setIsOpenTrackSelector(state);
    } else if (type == 'carpack') {
      setIsOpenCarpackSelector(state);
    }
  }

  function chooseTrack(id, layoutId = null) {
    let key = null;
    let layoutKey = null;
    for (const index in tracks) {
      if (tracks[index].id === id) {
        key = index;
        break;
      }
    }
    if (layoutId != null) {
    for (const index in tracks[key].layout) {
      if (tracks[key].layout[index].id === layoutId) {
        layoutKey = index;
        break;
      }
    }
    //console.log(tracks[key].layout[layoutKey]);
    }
    const trackName = tracks[key].name;
    if (layoutId != null) {
    const layoutName = tracks[key].layout[layoutKey].name;
    setTrackSelector(trackName + ' - ' + layoutName);
    } else {
    setTrackSelector(trackName);
    }
    //console.log(id);
    //console.log();
    //console.log(layoutKey);
    setTrack(tracks[key]);
    //console.log(tracks[key]);
    props.setServerTrack(tracks[key]);
    if (layoutId != null) {
    props.setServerTrackConfig(tracks[key].layout[layoutKey]);
    }

    //console.log(props.serverTrack);
    //console.log(serverTrackConfig);
    //navigate('/create-server/step-car-pack/');
    selector('track', false);
  }

  const [serverName, setServerName] = useState(null);
  const [carPacks, setCarPacks] = useState([]);
  const [selectedCarPack, setSelectedCarPack] = useState(0);
  const [isTractionControlAllowed, setIsTractionControlAllowed] = useState(true);
  const [isAnitLockBrakingSystemAllowed, setIsAnitLockBrakingSystemAllowed] = useState(true);

  const toggler = (parameter) => {
    if (parameter == 'tractionControl') {
      setIsTractionControlAllowed(!isTractionControlAllowed);
    } else if (parameter == 'anitLockBrakingSystem') {
      setIsAnitLockBrakingSystemAllowed(!isAnitLockBrakingSystemAllowed);
    }
  }

  const fetchCarPacks = async () => {
    try {
      const data = await getCarPacks(props.cookie.token);
      setCarPacks(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const data = await getTracks();
        setTracks(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    getRoles();
    fetchTracks();
    fetchCarPacks();
    
    if ((props.serverName != '' || isServerPublic == true) &&
      trackSelector != 'Select track' &&
      carpackSelector != 'Select car pack') {
      setIsLaunchDisabled(false);
    } else {
      setIsLaunchDisabled(true);
    }
  }, [props.serverName, trackSelector, carpackSelector, isServerPublic]);

  function chooseCarPack(id) {
    let key = null;
    for (const index in carPacks) {
      if (carPacks[index].id === id) {
        key = index;
        break;
      }
    }
    props.setServerCarPack(carPacks[key]);

    const carpackName = carPacks[key].name;
    setCarpackSelector(carpackName);
    selector('carpack', false);
  }

  async function deleteCarPack(id) {  
    try {
      const data = {
        id: id
      };

      const response = await axios.delete(`${API_URL}users/car-packs/${id}`, null, acServerApiHeader);

      if (response.status === 200) {
        fetchCarPacks();
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function launchServer() {

    setLink(null);
    setIsServerRequested(true);

    try {
      const data = {
        track: props.serverTrack,
        trackConfig: props.serverTrackConfig,
        carPack: props.serverCarPack,
        serverName: props.serverName,
        isServerPublic: isServerPublic,
        isTractionControlAllowed: isTractionControlAllowed,
        isAnitLockBrakingSystemAllowed: isAnitLockBrakingSystemAllowed
      };
      if (isServerPublic) {
        data.serverName = accmPrefix + props.serverTrack.name;
      }
      console.log(data);

      const response = await axios.post(`${API_URL}server/create/`, JSON.stringify(data), acServerApiHeader);

      if (response.status === 200) {
        setPort(response.data[0].port);
        setLink(`acmanager://race/online/join?query=race/online/join&ip=${AC_IP}&httpport=`+response.data[0].port);
        props.setServerName(null);
      } else {
        console.error('Request failed with status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className={styles.content}>
      <br></br>
      <br></br>
      <h2>Create Server</h2>
      <br></br>
      <div className={styles.newServerContainer}>
        <div className={styles.serverConfig}>
          <div>
            <p>Name</p>
            <input
              type="text"
              defaultValue={'Server name'}
              onChange={(e) => props.setServerName(e.target.value)}
              disabled={isServerPublic ? 'disabled' : null}
            />
            {isUserAdmin ? (
              <input
                type="button"
                value={isServerPublic ? 'Public' : 'Private'}
                onClick={() => toggleServerVisibility()}
              />
            ) : (null)}
          </div>
          <div>
            <input
              type="button"
              value={trackSelector}
              onClick={() => selector('track', true)}
            />
          </div>
          <div>
            <input
                type="button"
                value={carpackSelector}
                onClick={() => selector('carpack', true)}
              />
          </div>
        </div>
        <div className={styles.serverConfig}>
          <div>
            <label>
              Traction Control (TC):
              <input
                type="button"
                value={isTractionControlAllowed ? 'Allowed' : 'Not allowed'}
                onClick={() => toggler('tractionControl')}
              />
            </label>
          </div>
          <div>
            <label>
              Anti-lock braking system (ABS):
              <input
                type="button"
                value={isAnitLockBrakingSystemAllowed ? 'Allowed' : 'Not allowed'}
                onClick={() => toggler('anitLockBrakingSystem')}
              />
            </label>
          </div>
        </div>
      </div>
      {/*<div>
        <input
          type="button"
          value="Private"
          onChange={(e) => setServerName(e.target.value)}/>
      </div>
      <div>
        <input
          type="button"
          value="Plan"
          onChange={(e) => setServerName(e.target.value)}/>
      </div>*/}
      <div className={styles.createContent}>
        {link ? (
          <>
            <h2>Started</h2>
            <button
              onClick={() => {acLauncher(port)}}>
              Join now
            </button>
            <input
              className={styles.btnLaunch}
              type="button"
              value={translation.launchServerButton[language]}
              onClick={launchServer}
              disabled={isLaunchDisabled} />
          </>
        ) : (null)}
        {link ? (
          null
        ) : (
          <>
            {isServerRequested ? (
              <p>Launching...</p>
            ) : (
              <input
                className={styles.btnLaunch}
                type="button"
                value={translation.launchServerButton[language]}
                onClick={launchServer} 
                disabled={isLaunchDisabled} />
            )}
          </>
        )}
        <a className={styles.joinLink}>
          {link ? (
            <>
              {link}
              <button
                className={styles.joinLinkBtnCopy}
                onClick={() => {Clipboard(link)}}>
                <span className={`${styles.joinLinkBtnCopySpan} material-symbols-outlined`}>
                  content_copy
                </span>
              </button>
            </>
          ) : null}
        </a>
      </div>
      {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker className={styles.calendar} />
      </LocalizationProvider>
      {/*<DateTimePicker
        label="Controlled picker"
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />*/}
      <div className={`${
        isOpenTrackSelector == false ? styles.hidden : null
      }`}>
        <h2>Select Track</h2>
        <div className={styles.container}>
          {tracks.map((item, index) => (
            <div className={styles.track} key={index}>
              <div className={styles.content}>
                <p>{item.name}</p>
              </div>
              <div className={styles.layouts}>
                {typeof item.layout === 'undefined' ? (
                  <input
                    className={`${
                      props.selectedTrack === item.id ? styles.active : null
                    }`}
                    type="button"
                    value={item.name}
                    onClick={() => chooseTrack(item.id)}
                  />
                ) : (
                  <>
                    {item.layout.map((layoutItem, layoutIndex) => (
                      <input
                        key={layoutIndex}
                        className={`${
                          selectedTrack === item.id ? styles.active : null
                        }`}
                        type="button"
                        value={layoutItem.name}
                        onClick={() => chooseTrack(item.id, layoutItem.id)}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`${
        isOpenCarpackSelector == false ? styles.hidden : null
      }`}>
        <h2>Select Car Pack</h2>
        <div className={styles.container}>
          {carPacks.map((item, index) => (
            <div key={index} className={styles.track}>
              <div className={styles.content}>
                <p>{item.name}</p>
                {item.entry ? (
                  item.entry.map((subItem, subIndex) => (
                    <p key={subIndex}>{subIndex + 1}. {subItem.brand} {subItem.model} - {subItem.skin}</p>
                  ))
                ) : null}
                <div className={styles.layouts}>
                  <input
                    key={0}
                    type="button"
                    value="Select"
                    onClick={() => chooseCarPack(item.id)}
                  />
                  <input
                    key={1}
                    type="button"
                    value="Delete"
                    onClick={() => deleteCarPack(item.id)}
                  />
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
    </div>
  );
}

export default CreateServer;